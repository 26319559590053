export function shallowEqualObjects(objA, objB) {
  if (objA === objB) {
    return true
  }

  if (!objA || !objB) {
    return false
  }

  var aKeys = Object.keys(objA)
  var bKeys = Object.keys(objB)
  var len = aKeys.length

  if (bKeys.length !== len) {
    return false
  }

  for (var i = 0; i < len; i++) {
    var key = aKeys[i]

    if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
      return false
    }
  }

  return true
}